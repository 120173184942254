import React, {useState, useEffect, useMemo} from 'react';
import axios from "axios";
//import { useStore } from '../../Store';  
import Swal from 'sweetalert2';
import socketIOClient from "socket.io-client";
//import withReactContent from 'sweetalert2-react-content';
import AllegatiUpload from '../../Allegati_Upload_Component/Allegati-upload.component'
//import AllegatiUpload from '../../Allegati_Upload_Component/Allegati-upload.component'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
//test
//import Attive from './Attive';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import Endpoints from "../../../Component/services/Endpoints";

import { styled, createTheme, ThemeProvider } from '@mui/system';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableSospeseContenitoreCons from './TableSospeseContenitoreCons';




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text,
}));

const ENDPOINT = Endpoints.getUrls();

const SospeseSubCons = ({valore}) => {

   // const [store] = useStore();
    const [data, setData] = useState([])
    const [response, setResponse] = useState("");
    const [NewStatoPratica, setNewStatoPratica] = useState('');
    const [isClosedShow, setIsClosedShow] = useState(false);
    const [isNotClosedShow, setIsNotClosedShow] = useState(false);
    const [showUploader, setShowUploader] = useState(false);
   
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedConsulente, setSelectedConsulente] = useState({});

      //FileInfos anche se da warning che non è usato non è vero
    const [fileInfos, setFileInfos] = useState([]);
    const [progress, setProgress] = useState(0);
    const [ricevuta, setRicevuta] = useState([]);

    const [notex, setNotex] = useState('');
    const [selectedIdPratica, setSelectedIdPratica] = useState('');
    const [selectedDescrizione, setSelectedDescrizione] = useState('');

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [newRicevutaInfo, setNewRicevutaInfo] = useState({
        ricevutaFile: []
    });

    const [messages, setMessages] = useState([]);

        //data and fetching state
       
        const [isError, setIsError] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [isRefetching, setIsRefetching] = useState(false);
        const [rowCount, setRowCount] = useState(0);
      
        //from old component
     
        const [currentLoggedUser, setCurrentLoggedUser] = useState({});
     
        //table state
        const [columnFilters, setColumnFilters] = useState([]);
        const [globalFilter, setGlobalFilter] = useState('');
        const [sorting, setSorting] = useState([]);
        const [pagination, setPagination] = useState({
          pageIndex: 0,
          pageSize: 10,
        });
     
     
        // fetch pratiche in lavorazione by operatore id
         //if you want to avoid useEffect, look at the React Query example instead
         useEffect(() => {
       
           const fetchData = async () => {
             if (!data.length) {
               setIsLoading(true);
             } else {
               setIsRefetching(true);
             }
           
           //  console.log("TEST-RoleCons: ", localStorage.getItem('currentUser'));
             const cons = JSON.parse(localStorage.getItem('currentUser'));
             const id = cons._id;
       
            const url = new URL(`${ENDPOINT}pratiche/consulente/sospese/id?_id=${id}`,
               process.env.NODE_ENV === 'production'
                 ? 'https://www.material-react-table.com'
                 : 'http://localhost:3000',
             );
             url.searchParams.set(
               'start',
               `${pagination.pageIndex * pagination.pageSize}`,
             );
             url.searchParams.set('size', `${pagination.pageSize}`);
             url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
             url.searchParams.set('globalFilter', globalFilter ?? '');
             url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
       
             try {
               const response = await fetch(url.href);
               const json = await response.json();
               setData(json);
               setRowCount(response.headers.get('X-Total-Count'));
             } catch (error) {
               setIsError(true);
               console.error(error);
               return;
             }
             setIsError(false);
             setIsLoading(false);
             setIsRefetching(false);
           };
           fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
         }, [
           columnFilters,
           globalFilter,
           pagination.pageIndex,
           pagination.pageSize,
           sorting,
         ]);

         const renewTable = (val) =>{
        
            setData((data) => data.filter(({_id}) => _id !== val));
           
    }
     
     
       const columns = useMemo(
           () => [
             {
               accessorKey: '_id',
               header: '_id',
             },
             {
             accessorKey: 'pratica_numero',
              header: 'id',
              minSize: 20, //min size enforced during resizing
              maxSize: 80, //max size enforced during resizing
              size: 20, //medium column
             //column definitions...
             },
             {
               accessorKey: 'descrizione',
               header: 'Descrizione',
             },
             {
               accessorKey: 'assistito.regione',
               header: 'Regione',
             },
            
             {
               accessorKey: 'assistito.cognome',
               header: 'Assistito',
             },
        /*  */
             {
               //accessorFn function that combines multiple data together
             accessorFn: (row) => (row.assistito.codice_fiscale_assistito ? row.assistito.codice_fiscale_assistito : undefined),
             id: 'cf_assistito',
             header: 'CF.Assistito',
             },
     
             {
               //accessorFn function that combines multiple data together
             accessorFn: (row) => `${row.consulente.nome} ${row.consulente.cognome}`,
             id: 'consulente',
             header: 'Consulente',
             },
       
             {
               accessorKey: 'statoPratica',
               header: 'Stato',
             },
             {
               accessorKey: 'data_scadenza',
               header: 'Scade il',
             },
             
                   /*
             {
               accessorKey: d => `${d.storico_pratica[d.storico_pratica.length - 1].data}`,
               header: 'Inviata il',
             },
             */
             //end
             
           ],
           [],
       );
    

    function setIdPratica ({ pratica }) {

        console.log(pratica._id)
    }

    /*
    const renewTable = (val) => {
        setData((data) => data.filter(({_id}) => _id !== val.praticaId));

    }
*/
    const renderRowSubComponent = (row) => {
 //   console.log(row.original)

    let {
        allegato,
        operatore,
        data_scadenza,
        descrizione,
        note,
        ricevute,
        statoPratica,
        storico_pratica,
        storico_operatore,
        storico_note,
        ...rest
    } = row.original;
    let consulente = rest.consulente;
    let tipo_pratica = rest.tipo_pratica;
    let assistitoObj = rest.assistito;
    let collaboratore = rest.colloboratore;
    let pratica_id = rest._id;

    let statiPossibili = ['Lavorazione', 'Chiusa', 'Sospesa', 'Annullata'];
  //  console.log(row.row.row.original);
 //   console.log(rest.consulente);

    function notificaAlConsulente(data) {

        const notifyObj = {
        emailto: data.consulente.email,
        idpratica: data._id,
        descrizione: data.descrizione,
        stato: data.statoPratica,
        assistito: `${data.assistito.nome} ${data.assistito.cognome}`,
        operatore: `${data.operatore[data.operatore.length - 1].nome} ${data.operatore[data.operatore.length - 1].nome}`,
        contatti: `${data.operatore[data.operatore.length - 1].email}`
        }

        /*
        //notifico con mail al consulente
        axios.post(`${ENDPOINT}consulente/notifica/action`, notifyObj)
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
        */ 
    
    }

    

    const eliminaRiceuta = (path, index) => {

    alert(path, index);
    console.log(ricevuta);
    console.log(fileInfos)

    setRicevuta([
        ...ricevuta.slice(0, index),
        ...ricevuta.slice(index + 1)
    ]);

    setFileInfos([
        ...fileInfos.slice(0, index),
        ...fileInfos.slice(index + 1)
    ]);

    axios({
        method: 'get',
        url: `${ENDPOINT}pratica/delete/ricevuta/path?path=${path}`
    })
        .then(function (response) {
            console.log(response);
        });
    }

    /* NUOVO UPLOADER */
    const handleFiles = () => {
            setIsVisible(true);
            let selectedFile = newRicevutaInfo.ricevutaFile;

            console.log('Test Files');

            const data = new FormData()
            for (var x = 0; x < selectedFile.length; x++) {
                data.append('rcvCollection', selectedFile[x]);
            }
            const headers = {"Content-Type": "multipart/form-data"};

            // ATTENZIONE A DOVE CARICA
            axios.post(`${ENDPOINT}ricevute`, data, {
                headers: headers,
                // receive two    parameter endpoint url ,form data
                onUploadProgress: ProgressEvent => {
                    setProgress((ProgressEvent.loaded / ProgressEvent.total * 100))
                },
            }).then(response => { // then print response status
                console.log(response);
                setFileInfos(response.data);

                let ricevuteArray = [];

                for (let i = 0; i < response.data.length; i++) {
                    ricevuteArray.push({
                        path: response.data[i].filename,
                        creato: new Date().toISOString()
                    })
                }

                setRicevuta(ricevuteArray);

            })
            //********************* */
    }

    const handleSelect = (event) => {
        event.stopPropagation();
        setShowUploader(true);

    //alert('Your favorite flavor is: ' + event.target.value);
    if (event.target.value.toString() === 'Chiusa') {

        alert('Stato pratica selezionato: ' + event.target.value);
        setIsClosedShow(true);
        setNewStatoPratica(event.target.value);
    } else {
        setIsClosedShow(false);
    }

    if (event.target.value.toString() !== 'Chiusa') {

        alert('Stato pratica selezionato: ' + event.target.value);
        setIsNotClosedShow(true);
        setNewStatoPratica(event.target.value);
    } else {
        setIsNotClosedShow(false)
    }


    event.preventDefault();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
       
        let aggiornaObj = {
           // _id: store(),
           _id: selectedIdPratica,
            statoPratica: NewStatoPratica,
            ricevuta: ricevuta,
            note: notex,
            storico_pratica: {
                stato: NewStatoPratica,
                data: new Date().toLocaleString('it-IT')
            }
        }
        console.log(aggiornaObj);

        const headers = {
            "Content-Type": "application/json",
        }

        axios.post(`${ENDPOINT}pratiche/cambiostato/`, aggiornaObj, {
            headers: headers,
        })
            .then(response => { // then print response status
                console.log(response);

                fetch(`${ENDPOINT}pratiche/inserita/id?_id=${aggiornaObj._id}`, {
                        headers: {
                            'Content-Type': 'application/json'
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                ).then(response => response.json())
                    .then(data => notificaAlConsulente(data));


                Swal.fire({
                    title: 'Cambio stato',
                    text: `Hai cambiato questa pratica in: ${NewStatoPratica}`,
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK!',
                    footer: `<b>Operatore<b/>`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        const socket = socketIOClient(ENDPOINT);

                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));

                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                       // renewTable(updTbl);
                    } else {


                        const socket = socketIOClient(ENDPOINT);

                        socket.emit('cambiostato', JSON.stringify({pratica: 'aggiornata'}));

                        let updTbl = {
                            praticaId: selectedIdPratica
                        }

                      //  renewTable(updTbl);

                    }
                })


            })

        //fine
    }

    const RenderAllegati = () => {
        return (

            <div>
        {allegato?.map((rowData, index) => ( 

            <Stack key={index}
                direction="row"
                sx={{ width: '100%' }}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={12}
            >
                <Item key={index+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                <Item key={index+2} sx={{boxShadow: 'none'}}>{'url: '}<a href={`${ENDPOINT}static/allegati/${rowData.path}`} download target="_blank" rel="noopener noreferrer">{rowData.path}</a></Item>
                <Item key={index+3} sx={{boxShadow: 'none'}}>{'Data: '}{rowData.creato}</Item>
            </Stack>   
            
        ))}
        </div>          
        )
    }

    const RenderStoricoRicevute = () => {
        return (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="right">tipo</TableCell>
                    <TableCell align="right">Url</TableCell>
                    <TableCell align="right">Creato</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ricevute?.map((rowData, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right"><i className="bi bi-file-pdf" style={{color: 'red'}}></i></TableCell>
                      <TableCell align="right"><a href={`${ENDPOINT}static/pratiche/ricevute/${rowData.path} `} download
                                           target="_blank" rel="noopener noreferrer">
                                        {rowData.path}</a></TableCell>
                      <TableCell align="right">{rowData.creato}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
    }

    const RenderStoricoPratica = () => {


        return (
            
            <div>
            {storico_pratica?.map((rowData, index) => (
                <Stack key={index}
                direction="row"
                sx={{ width: '100%' }}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={12}
            >
                <Item key={index+1} sx={{boxShadow: 'none'}}><AvTimerIcon /></Item>
                <Item key={index+2} sx={{boxShadow: 'none'}}>{'Stato: '}{rowData.stato}</Item>
                <Item key={index+3} sx={{boxShadow: 'none'}}>{'Data: '}{rowData.data}</Item>
            </Stack>   
              ))}
              </div>
            
          );
    }

    const RenderStoricoNote = () => {
        return (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="right">Nota</TableCell>
                    <TableCell align="right">Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storico_note?.map((rowData, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right">{rowData.nota}</TableCell>
                      <TableCell align="right">{rowData.data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
    }

    const RenderStoricoOperatore = () => {
        return (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="right">_id</TableCell>
                    <TableCell align="right">Nome</TableCell>
                    <TableCell align="right">Cognome</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storico_operatore?.map((rowData, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right">{rowData.id}</TableCell>
                      <TableCell align="right">{rowData.nome}</TableCell>
                      <TableCell align="right">{rowData.cognome}</TableCell>
                      <TableCell align="right">{rowData.email}</TableCell>
                      <TableCell align="right">{rowData.data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
    }
// funzione per gestire l'array delle ricuvute
    const updateUploadedFiles = (files) =>
    setNewRicevutaInfo({...newRicevutaInfo, ricevutaFile: files});
    
    const RenderNonClosedCase2 = () => {

        return (
            <div >
                <AllegatiUpload
                    accept=".pdf"
                    label="Area caricamento ricevute"
                    multiple
                    updateFilesCb={updateUploadedFiles}
                />

                {isVisible ?
                  <>
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={progress} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="text.secondary">
                      {`${progress}%`}
                    </Typography>
                  </Box>
                </Box>
                  
                <Paper elevation={0} />
 {/*                    
                        <div>
                            {fileInfos &&
                                fileInfos.map((fileInfo, index) => (

                                  <Box sx={{ width: '100%' }}>
                                  <Typography  component="div" style={{ padding: 6 * 2, fontSize:'small' }}>{fileInfo.path}</Typography>
                                  <CircularProgress variant="determinate" value={progress} />
                                </Box>
                                ))}

                        </div>
*/}
                        <div >
                            

                                {fileInfos.map((el, i) => {
                                    return (
                                    <>
                                    <Stack key={i}
                                        direction="row"
                                        sx={{ width: '100%' }}
                                        divider={<Divider  flexItem />}
                                        spacing={2}
                                    >
                                        <Item key={i+1} sx={{boxShadow: 'none'}}><PictureAsPdfIcon sx={{color: 'red'}} /></Item>
                                        <Item key={i+2} sx={{boxShadow: 'none'}}><a style={{color: 'orange'}}href={`${ENDPOINT}static/pratiche/ricevute/${el.filename}` }
                                         target="_blank" rel="noopener noreferrer" downlod="true">{el.filename}</a>
                                         </Item>
                                        <Item key={i+3} sx={{boxShadow: 'none'}}><button type="button" className="btn btn-danger btn-sm"
                                        style={{float: 'right'}}
                                        onClick={() => eliminaRiceuta(el.filename, i)}>Elimina sul server</button></Item>
                                       
                                    </Stack> 
                                    <Divider />
                                    </>
                                    )

                                })}
                               
                        
                                </div>

                        {newRicevutaInfo.ricevutaFile && newRicevutaInfo.ricevutaFile.length >0 ?
                        <Stack spacing={2} style={{boxShadow: 'none'}}>
                        <Item  style={{boxShadow: 'none'}}>
                        <Button 
                        sx={{float:'rigth'}}
                        onClick={handleFiles}
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        >Carica i documenti sul server remoto!</Button></Item>
                        </Stack>
                      : null }

                        <Box
                        component="div"
                        sx={{
                          '& > :not(style)': { m: 1, width: '90%' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField 
                        sx={{width: '90%'}}
                        name='note'
                        onChange={e => setNotex(e.target.value)}
                        defaultValue={''}
                        id="outlined-basic" label="Aggiungi Note" variant="outlined" />


                        <Stack spacing={2} sx={{boxShadow: 'none'}}>
                        <Item  style={{boxShadow: 'none'}}><Button 
                        sx={{float:'rigth'}}
                        disabled={!selectedFiles}
                        onClick={handleSubmit}
                        variant="contained">Cambia lo stato pratica!</Button></Item>
                      </Stack>
                        
                      </Box>



                    
                    <Paper />
                    </>
                    : null}
                   
            </div>
        )
    }



    return (
        <>
            <Container maxWidth="lg">

                <Divider className={"container"} component="div" role="presentation"
                    sx={{ marginTop: '20px', marginBottom: '20px' }}>
                    {/* any elements nested inside the role="presentation" preserve their semantics. */}
                    <Typography variant="h6">Consulente:</Typography>
                </Divider>
                <Grid container fixed="true" spacing={2}>
                    <Grid item xs={4}>
                        <Item> <small style={{ color: 'rgb(131 135 140)' }}>Cognome: </small>
                            {`${consulente.cognome} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}>Nome: </small>
                            {`${consulente.nome} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}> Email: </small>
                            {`${consulente.email} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}> Cellulare: </small>
                            {`${consulente.cellulare} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item><small style={{ color: 'rgb(131 135 140)' }}> Telefono: </small>
                            {`${consulente.telefono} `}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" endIcon={<SendIcon />} size="small">
                        Invia Messaggio al consulente
                      </Button>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="lg">
            <Divider className={"container"} component="div" role="presentation"
            sx={{marginTop: '20px', marginBottom: '20px'}}>
       {/* any elements nested inside the role="presentation" preserve their semantics. */}
       <Typography variant="h6">Tipo pratica:</Typography>
    </Divider>
            <Grid container  fixed="true" spacing={2}>
            <Grid item xs={4}>
                <Item> <small style={{color: 'rgb(131 135 140)'}}>descrizione: </small>
                {`${descrizione} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>tipo pratica: </small>
                {`${tipo_pratica} `}</Item>
            </Grid>
            </Grid>
            </Container>


            <Container maxWidth="lg">
            <Divider className={"container"} component="div" role="presentation"
                sx={{ marginTop: '20px', marginBottom: '20px' }}>
                {/* any elements nested inside the role="presentation" preserve their semantics. */}
                <Typography variant="h6">Assistito:</Typography>
            </Divider>
            <Grid container fixed="true" spacing={2}>
                <Grid item xs={4}>
                    <Item> <small style={{color: 'rgb(131 135 140)'}}>Cognome: </small>
                    {`${assistitoObj.cognome} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><small style={{color: 'rgb(131 135 140)'}}>Nome: </small>
                    {`${assistitoObj.nome} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item> <small style={{color: 'rgb(131 135 140)'}}>C.F.: </small>
                    {`${assistitoObj.codice_fiscale_assistito} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><small style={{color: 'rgb(131 135 140)'}}>Prov.: </small>
                    {`${assistitoObj.provincia} `}</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><small style={{color: 'rgb(131 135 140)'}}>Comune: </small>
                    {`${assistitoObj.comune} `}</Item>
                </Grid>
                <Grid item xs={4}>
                <Item> <small style={{color: 'rgb(131 135 140)'}}>Via: </small>
                {`${assistitoObj.indirizzo} - ${assistitoObj.cap} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>Tipo assistito: </small>
                {`${assistitoObj.categoria_assistito} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>sc: </small>
                {`${assistitoObj.stato_civile} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>Email: </small>
                {`${assistitoObj.email} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item> <small style={{color: 'rgb(131 135 140)'}}>Cellulare: </small>
                {`${assistitoObj.cellulare} `}</Item>
            </Grid>
            <Grid item xs={4}>
                <Item><small style={{color: 'rgb(131 135 140)'}}>Tel.: </small>
                {`${assistitoObj.telefono} `}</Item>
            </Grid>
            </Grid>
           </Container>

        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
    <Typography variant="h6">Collaboratore volontario:</Typography>
    </Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={4}>
            <Item> <small style={{color: 'rgb(131 135 140)'}}>Cognome: </small>
            {`${collaboratore.cognome} `}</Item>
        </Grid>
        <Grid item xs={4}>
            <Item><small style={{color: 'rgb(131 135 140)'}}>Nome: </small>
            {`${collaboratore.nome} `}</Item>
        </Grid>
        <Grid item xs={4}>
            <Item><small style={{color: 'rgb(131 135 140)'}}>C.F: </small>
            {`${collaboratore.codice_fiscale} `}</Item>
        </Grid>
        </Grid>
        </Container>

        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
   <Typography variant="h6">Stato pratica:</Typography>
</Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={4}>
            <Item>  <small style={{color: 'rgb(131 135 140)'}}>stato: </small>
            {`${statoPratica} `}</Item>
        </Grid>
      
        </Grid>
        </Container>


        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
   <Typography variant="h6">Documenti allegati:</Typography>
</Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>  {RenderAllegati()}</Item>
        </Grid>
      
        </Grid>
        </Container>
        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
   <Typography variant="h6">Storico pratica:</Typography>
</Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>  {RenderStoricoPratica()}</Item>
        </Grid>
      
        </Grid>
        </Container>

        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
   <Typography variant="h6">Storico Note:</Typography>
</Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>{RenderStoricoNote()}</Item>
        </Grid>
      
        </Grid>
        </Container>


        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
   <Typography variant="h6">Storico Ricevuta:</Typography>
</Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>{RenderStoricoRicevute()}</Item>
        </Grid>
      
        </Grid>
        </Container>


        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
   {/* any elements nested inside the role="presentation" preserve their semantics. */}
   <Typography variant="h6">Storico Operatore:</Typography>
</Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>{RenderStoricoOperatore()}</Item>
        </Grid>
      
        </Grid>
        </Container>


        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
        {/* any elements nested inside the role="presentation" preserve their semantics. */}
        <Typography variant="h6">Stato Pratica attuale:</Typography>
        </Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12}>
            <Item>{`${statoPratica}`}</Item>
        </Grid>
      
        </Grid>
        </Container>


        <Container maxWidth="lg">
        <Divider className={"container"} component="div" role="presentation"
        sx={{marginTop: '20px', marginBottom: '20px'}}>
        {/* any elements nested inside the role="presentation" preserve their semantics. */}
        <Typography variant="h6">Cambia lo stato pratica qui:</Typography>
        </Divider>
        <Grid container  fixed="true" spacing={2}>
        <Grid item xs={12} component="div">
        <Item component="div"> 
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <select defaultValue={statoPratica}
                    className="form-select form-select-lg mb-3"
                    aria-label="Default select example"
                    onChange={(event) => {
                        handleSelect(event);
                        setSelectedIdPratica(pratica_id);
                      }}
                    >
                    {statiPossibili.map((item, i) => {
                        return <option key={i} value={item}>{item}</option>
                        })}
                    </select>
                        <p/>
                        {showUploader ? RenderNonClosedCase2() : null}


            </form>
        </Item>
        </Grid>
      
        </Grid>
        </Container>


        </>


    );
}

    return (
        <TableSospeseContenitoreCons data={data} columns={columns} renderRowSubComponent={renderRowSubComponent} renewTable={renewTable} />
    );
}

export default SospeseSubCons;