import * as React from 'react';
import { useNavigate, Navigate,  Link, generatePath, } from 'react-router-dom';
import { redirect } from "react-router-dom";
import { useState, useEffect } from 'react'; 
import { styled } from '@mui/material/styles';
import Endpoints from '../../Component/services/Endpoints';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

 

const ENDPOINT = Endpoints.getUrls();

export default function ElencoPratiche() {

  const navigate = useNavigate();
    //data and fetching state
    const [resources, setResources] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [expanded, setExpanded] = React.useState('panel1');


   const [ tipoPratica,setTipoPratica ] = useState('');

   const [filtered, setFiltered] = useState([]);
   const [altrePratiche, setAltrePratiche] = useState([]);
   const [assegniNucleoFamiliare, setassegniNucleoFamiliare] = useState([]);
   const [certificazioni, setCertificazioni] = useState([]);
   const [disoccupazioneMobilita, setDisoccupazioneMobilita] = useState([]);
   const [emergenzaCovid19, setEmergenzaCovid19] = useState([]);
   const [pensioni, setPensioni] = useState([]);
   const [maternita, setMaternita] = useState([]);
   const [permessiCongedi, setPermessiCongedi] = useState([]);
   const [ratei, setRatei] = useState([]);
   const [ricostituzioni, setRicostituzioni] = useState([]);
   const [sostegnoAlReddito, setSostegnoAlReddito] = useState([]);
   const [versamentiVolontari, setVersamentiVolotari] = useState([]);
   const [history, setHystory] = useState([]);
   const [redirect, setRedirect] = useState(false);







  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


    //if you want to avoid useEffect, look at the React Query example instead
    const createFilteredArray = (array) => {

        let superArray =[];
        let result = array.reduce(function (r, a) {
            r[a.tipo_pratica.toLowerCase()] = r[a.tipo_pratica.toLowerCase()] || [];
            r[a.tipo_pratica.toLowerCase()].push(a);
            return r;
        }, Object.create(null));
    
    
    superArray.push(result);
    console.log(superArray);
      setFiltered(result);
      setAltrePratiche(result["altre pratiche"]);
      setassegniNucleoFamiliare(result["assegni nucleo familiare"]);
      setCertificazioni(result['certificazioni']);
      setDisoccupazioneMobilita(result['disoccupazioni e mobilità']);
      setEmergenzaCovid19(result['emergenza covid-19']);
      setPensioni(result['pensioni']);
      setMaternita(result['maternità']);
      setPermessiCongedi(result['permessi e congedi']);
     setRatei(result['ratei']);
      setRicostituzioni(result['ricostituzioni']);
      setSostegnoAlReddito(result['sostegno al reddito']);
      setVersamentiVolotari(result['versamenti volontari']);
      }


    useEffect(() => {

        const fetchData = async () => {
    /*
          if (!resources?.length) {
    
            setIsLoading(true);
    
          } else {
    
            setIsRefetching(true);
    
          }
    */
    
          const url = new URL(
    
            `${ENDPOINT}tipopratica/`,
    
            process.env.NODE_ENV === 'production'
    
              ? 'https://www.material-react-table.com'
    
              : 'http://localhost:3000',
    
          );
      
    
          try {
    
            const response = await fetch(url.href);
            const json = (await response.json());
            setResources(json);
            createFilteredArray(json)
            setRowCount(json.meta.totalRowCount);
          } catch (error) {
            setIsError(true);
            console.log(error);
            return;
    
          }
          setIsError(false);
          setIsLoading(false);
          setIsRefetching(false);
    
        };
    
        fetchData();
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    
      }, []);

      const handleRowClick = (val) => navigate(`/consulente/pratiche/nuova_pratica`, { state: val, replace: false });

 const handleRowClicknnnnnn = (val) => {
        console.log(val);
       // navigate('/consulente/pratiche/test_pratica')
       navigate(`/consulente/pratiche/test_pratica`, { state: val, replace: false }); // <-- redirect
      //  return <Navigate to="/consulente/pratiche/nuova_pratica" state={val} replace={true} />
       };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="fixed">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Altre Pratiche</Typography>
        </AccordionSummary>
        <AccordionDetails>    
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
        {altrePratiche?.map((item, i) => {
           
            return (
          <TableRow
              key={i+10}
              hover
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.descrizione}
              </TableCell>
              <TableCell align="right">
              <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button>
            {/* 
              <button onClick={() => navigate("/consulente/pratiche/nuova_pratica", { state: item._id, replace: true })}>Crea</button>
*/}
              </TableCell>
            </TableRow>
            
            );

          })}
             </TableBody>
      </Table>
    </TableContainer>

          
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Assegni nucleo familiare</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
          {assegniNucleoFamiliare?.map((item, i) => {
             
              return (
            <TableRow
            key={i+20}
              hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.descrizione}
                </TableCell>
                <TableCell align="right">              <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
              </TableRow>
              
              );
  
            })}
               </TableBody>
        </Table>
      </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Certificazioni</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
          {certificazioni?.map((item, i) => {
             
              return (
            <TableRow
            key={i+30}
              hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.descrizione}
                </TableCell>
                <TableCell align="right"><Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
              </TableRow>
              
              );
  
            })}
               </TableBody>
        </Table>
      </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
      <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
        <Typography>Disoccupazioni e mobilità</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
        {disoccupazioneMobilita?.map((item, i) => {
           
            return (
          <TableRow
              key={i+40}
              hover
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.descrizione}
              </TableCell>
              <TableCell align="right">
              <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
            </TableRow>
            
            );

          })}
             </TableBody>
      </Table>
    </TableContainer>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
      <Typography>Emergenza covid-19</Typography>
    </AccordionSummary>
    <AccordionDetails>
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableBody>
      {emergenzaCovid19?.map((item, i) => {
         
          return (
        <TableRow
        key={i+50}
              hover
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {item.descrizione}
            </TableCell>
            <TableCell align="right">
            <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
          </TableRow>
          
          );

        })}
           </TableBody>
    </Table>
  </TableContainer>
    </AccordionDetails>
  </Accordion>

    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
      <Typography>Maternità</Typography>
    </AccordionSummary>
    <AccordionDetails>
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableBody>
      {maternita?.map((item, i) => {
         
          return (
        <TableRow
        key={i+60}
              hover
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {item.descrizione}
            </TableCell>
            <TableCell align="right">
            <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
          </TableRow>
          
          );

        })}
           </TableBody>
    </Table>
  </TableContainer>
    </AccordionDetails>
  </Accordion>

  <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
  <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
    <Typography>Pensioni</Typography>
  </AccordionSummary>
  <AccordionDetails>
  <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableBody>
    {pensioni?.map((item, i) => {
       
        return (
      <TableRow
      key={i+70}
      hover
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {item.descrizione}
          </TableCell>
          <TableCell align="right">
          <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
        </TableRow>
        
        );

      })}
         </TableBody>
  </Table>
</TableContainer>
  </AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
<AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
  <Typography>Permessi e congedi</Typography>
</AccordionSummary>
<AccordionDetails>
<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableBody>
  {permessiCongedi?.map((item, i) => {
     
      return (
    <TableRow
    key={i+80}
    hover
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {item.descrizione}
        </TableCell>
        <TableCell align="right">
        <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
      </TableRow>
      
      );

    })}
       </TableBody>
</Table>
</TableContainer>
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
<AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
  <Typography>Ricostituzioni</Typography>
</AccordionSummary>
<AccordionDetails>
<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableBody>
  {ricostituzioni?.map((item, i) => {
     
      return (
    <TableRow
    key={i+90}
    hover
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {item.descrizione}
        </TableCell>
        <TableCell align="right">
        <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
      </TableRow>
      
      );

    })}
       </TableBody>
</Table>
</TableContainer>
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
<AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
  <Typography>Sostegno al reddito</Typography>
</AccordionSummary>
<AccordionDetails>
<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableBody>
  {sostegnoAlReddito?.map((item, i) => {
     
      return (
    <TableRow
        key={i}
        hover
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {item.descrizione}
        </TableCell>
        <TableCell align="right">
        <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
      </TableRow>
      
      );

    })}
       </TableBody>
</Table>
</TableContainer>
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
<AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
  <Typography>Versamenti volontari</Typography>
</AccordionSummary>
<AccordionDetails>
<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableBody>
  {versamentiVolontari?.map((item, i) => {
     
      return (
    <TableRow
    key={i}
    hover
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {item.descrizione}
        </TableCell>
        <TableCell align="right">
        <Button href={`/consulente/pratiche/nuova_pratica?ids=${item._id}`} state={item._id}  variant="outlined">
              Crea Pratica
            </Button></TableCell>
      </TableRow>
      
      );

    })}
       </TableBody>
</Table>
</TableContainer>
</AccordionDetails>
</Accordion>
   
 </Container>
</React.Fragment>
  );
}